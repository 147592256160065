<template>
  <div class="content">
    <PageHeader :title="$t('acc-settings.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-7">
          <div class="card">
            <form @submit.prevent="submitData" class="form-horizontal">
              <div class="card-body d-flex">
                <div class="col-lg-2 d-flex justify-content-end">
                  <div class="profile-picture-wrapper">
                    <!-- Condition profile picture not found -->
                    <span class="profile-pic no-user-pic profile-random-color"><p class="mb-0">C</p></span>
                  </div>
                </div>
                <div class="form-body col">
                  <div class="form-group row">
                    <!-- <label class="control-label text-right col-md-3 text-left-m">{{$tc('generic-str.name', 1)}}</label> -->
                    <label class="control-label text-right col-md-2 text-left-m"
                      ><span class="material-icons">
                        person_outline
                      </span></label
                    >
                    <div class="col-md-5">
                      <input
                        v-model="form.name"
                        type="text"
                        :placeholder="$tc('generic-str.name', 1)"
                        class="form-control"
                        autocomplete="given-name"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <!-- <label
                      class="control-label text-right col-md-2 text-left-m"
                      >{{ $t('generic-str.lbl-cellphone') }}</label
                    > -->
                    <label class="control-label text-right col-md-2 text-left-m"
                      ><span class="material-icons-outlined">
                        call
                      </span></label
                    >
                    <div class="col-md-5">
                      <vue-tel-input
                        v-model="mobile_number"
                        v-bind="bindProps"
                        @validate="numberChanges"
                      ></vue-tel-input>
                      <div
                        class="invalid-feedback"
                        :class="{ show: invalidPhone }"
                      >
                        {{ $t('login-invite.invalid-phone') }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-right col-md-2 text-left-m"
                      ><span class="material-icons-outlined">
                        email
                      </span></label
                    >
                    <div class="col-md-5">
                      <input
                        v-model="form.email"
                        type="email"
                        :placeholder="`${$t('generic-str.example')}@gmail.com`"
                        class="form-control"
                        autocomplete="email"
                      />
                    </div>
                  </div>
                  <!-- <div class="form-group row" v-if="!changePass">
                    <label
                      class="control-label text-right col-md-2 text-left-m"
                    ></label>
                    <div class="col-md-5">
                      <button
                        class="btn btn-danger btn-rounded"
                        @click="changePass = true"
                      >
                        {{ $t('acc-settings.chg-pass') }}
                      </button>
                    </div>
                  </div> -->
                  <!-- <div class="form-group row" v-if="changePass"> -->
                    <div class="form-group row">
                    <label class="control-label text-right col-md-2 text-left-m"
                      ><span class="material-icons-outlined">
                        lock
                      </span></label
                    >
                    <div class="col-md-5">
                      <div class="input-group password_wrapper mb-3">
                        <input
                          v-model="newPass"
                          :type="type"
                          class="form-control"
                        />
                        <div class="input-group-prepend">
                          <button
                            type="button"
                            class="btn eyes_btn"
                            @click="toggleVisibility"
                          >
                           <span class="material-icons eye_icon">
                              {{`${show==false? 'visibility': 'visibility_off'}`}}
                            </span>
                            <!-- <i class="fas fa-eye"></i> -->
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-right col-md-2 text-left-m"
                      ><span class="material-icons-outlined"> language </span>
                      <!-- <span class="badge badge-warning">Beta</span> --></label
                    >
                    <div class="col-md-5">
                      <OptLang />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="form-actions">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="offset-sm-3 col-md-5">
                          <button
                            :disabled="isSending"
                            class="btn btn-yup-purple btn-rounded"
                            :class="{
                              'qt-loader qt-loader-mini qt-loader-right':
                                isSending,
                            }"
                          >
                            {{ $t('generic-str.save') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import UserService from '@/services/user.service';
import PageHeader from '@/components/PageHeader.vue';
import { VueTelInput } from 'vue-tel-input';
import OptLang from '@/components/rebranding/OptLang.vue';

export default {
  name: 'Home',
  components: {
    PageHeader,
    VueTelInput,
    OptLang,
  },
  data() {
    return {
      type: 'password',
      invalidPhone: false,
      mobile_number: null,
      changePass: true,
      isSending: false,
      newPass: null,
      infoUser: {},
      show: false,
      form: {
        name: '',
        email: '',
        mobile_number: '',
      },
      bindProps: {
        mode: 'international',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
    };
  },
  created() {
    this.fetchInfo();
  },
  methods: {
    numberChanges(rest) {
      if (rest.number.input.length > 0) {
        this.invalidPhone = !rest.isValid;
        if (rest.isValid) {
          this.mobile_number = rest.number.national;
          this.form.mobile_number = rest.number.input;
        }
      }
    },
    toggleVisibility() {
      this.type = this.type === 'password' ? 'text' : 'password';
      this.show = !this.show;
    },
    fetchInfo() {
      UserService.getInfo().then(
        (response) => {
          this.form = response;
          this.mobile_number = this.form.mobile_number;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    submitData() {
      if (!this.invalidPhone) {
        this.isSending = true;
        if (this.changePass && this.newPass != null) {
          this.form.password = this.newPass;
        }
        UserService.updateUserInfo(this.form).then(
          (response) => {
            this.isSending = false;
            this.form = response;
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('acc-settings.toast.updated'),
              type: 'success',
            });
          },
          (error) => {
            this.isSending = false;
            this.content = error;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                error.code
              }`,
              content: error.message,
              type: 'danger',
            });
          },
        );
      }
    },
  },
};
</script>

<style lang="scss">
.vti__dropdown-list {
  z-index: 1000 !important;
}
.vue-tel-input {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
}

input:-ms-input-placeholder {
  text-transform: lowercase !important;
}
input::-webkit-input-placeholder {
  text-transform: lowercase !important;
}
input::placeholder {
  text-transform: lowercase !important;
}

.eye_icon {
  color: #bfc5cb;
}
.eyes_btn {
  background-color: var(--form-input);
  border: 1px solid var(--form-border);
  border-left-color: transparent;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-group {
  .material-icons,
  .material-icons-outlined {
    color: #9ca7b0;
  }
}

.password_wrapper input{
    border-right-color: transparent;
    border-right-width: 0;
}
/* Profile pic not found */
.no-user-pic {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 92px;
  height: 92px;
  background-color: #FFF0CE;
  border-radius: 50px;
  cursor: pointer;
  transition: .5s;
  overflow: hidden;
  border: 0px solid transparent;
}

.no-user-pic:hover {
  width: 92px;
  height: 92px;
  border: 1px solid var(--clr-yup-purple);
  background-color: #c2c2;
}

.no-user-pic p {
  position: absolute;
  font-weight: bold;
  font-size: 32px;
  opacity: 1;
  transition: .5s;
}

.no-user-pic:hover p {
  opacity: 0;
}

.no-user-pic::before {
  content: '\e412';
  font-family: 'Material Symbols Outlined';
  font-size: 32px;
  font-weight: 400;
  color: var(--clr-yup-purple);
  opacity: 0;
  transition: .5s;
  height: 38px;
}

body {
  &.pt {
    .no-user-pic:after {
      content: 'Alterar';
    }
  }
  &.en {
    .no-user-pic:after {
      content: 'Change';
    }
  }
  &.es {
    .no-user-pic:after {
      content: 'Cambiar';
    }
  }
}

.no-user-pic:after {
  font-size: 12px;
  font-weight: 400;
  color: var(--clr-yup-purple);
  opacity: 0;
  transition: .5s;
}

.no-user-pic:hover::after, .no-user-pic:hover::before {
  opacity: 1;
}

.lighter, .lighter p {
  color: #FFF !important;
}

.profile-picture-wrapper .profile-pic p, .profile-picture-wrapper .profile-pic {
  color: #000;
}
</style>
